import React, { useEffect, Fragment, useMemo, useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TextInput } from 'react-native-paper';
import { v4 } from 'uuid';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import {
  MessageCircleIcon,
  ArrowUpFromLineIcon,
  PlusInCircleIcon,
  SearchIcon,
} from 'assets/icons';
import unifiedCommsService from '../../api/UnifiedCommsService';
import FileStorageService from '../../api/FileStorageService';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { Avatar } from 'assets/components/avatar';
import { ToolbarSortingDropDown } from 'assets/components/data-grid/data-grid-toolbar';
import { useAppStateStore } from '../../store/app-store';
import { notImplementedAlert } from 'assets/utils/alert';
import { PatientConversation } from './components/PatientConversation';
import { ConversationBox } from './components/ConversationBox';
import {
  ConversationDto,
  DirectMessagePatientDto,
  AuthorType,
  MessageTemplateDto,
  ClientAttachmentDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { StorageKeys } from '../../../enums/storage-keys';
import { User } from 'react-native-gifted-chat';
import { getFullName, compare, groupConversationsByPatient } from './utils';
import PatientService from '../../api/PatientService';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import { formatDate } from '../../common/datetime-utils';
import {
  sortingOptions,
  MESSAGE_LIMIT,
  CONVERSATIONS_TABS_WIDTH,
  ERROR_NO_MESSAGE,
  ERROR_MESSAGE_LIMIT_FIVE_THOUSAND,
  ERROR_FILES_SENDING,
  ERROR_FILES_SENDING_MODAL,
  LOADING_CONVERSATION_CREATING,
  EXCLUDED_ERRORS_INBOX,
  LOADING_MESSAGES,
  MESSAGES_COUNT_LIMIT,
} from './data';
import {
  NewChatModal,
  CreateConversationData,
} from './components/NewChatModal';
import {
  Order,
  DirectMessageExtended,
  IMessageExtended,
  IComposedAttachments,
  IUploadFilesResult,
  BaseFilterValues,
  MultiFilterValues,
  TypedMessage,
  GetMessagesResult,
} from './types';
import { IFile } from '../../api/types';
import { useUserState } from '../../store/user-store';
import { useInboxState } from './inbox-store/inbox-store';
import {
  setPatients,
  setSelectedPatient,
  setSelectedConversation,
  setViewedConversations,
  setConversationsSorting,
  setMessagesGroups,
  setSelectedMessages,
  setRawConversations,
  removeSelectedPatient,
  removeSelectedConversation,
  removeSelectedMessages,
  openNewChatModal,
  closeNewChatModal,
  setPatientFilter,
  setTemplates,
  setCounts,
  setMessagesPagination,
} from './inbox-store/inbox-actions';
import { useSockets } from './hooks/useSockets';
import { setInputFieldsData } from './new-chat-modal-store/new-chat-modal-actions';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useErrorState } from './error-store/error-store';
import { ErrorsContainer } from './components/ErrorsContainer';
import { setLoading } from './loading-store/loading-actions';
import { useLoadingState } from './loading-store/loading-store';
import { ErrorStatus } from './error-store/error-store';
import { setError } from './error-store/error-actions';
import VerificationAlert from './VerificationAlert';

export default function Inbox() {
  const { locationId, pharmacyId } = useAppStateStore();
  const {
    patients,
    selectedPatient,
    selectedConversation,
    viewedConversations,
    conversationsSorting,
    messagesGroups,
    selectedMessages,
    rawConversations,
    isOpenNewChatModal,
    patientFilter,
    templates,
    baseFilter,
    multiFilters,
    messagesPagination,
  } = useInboxState();
  const userData = useUserState();
  const { errorObject } = useErrorState();
  const { loadingObject } = useLoadingState();

  const theme = useTheme();
  const styles = useStyles();

  const { typingMember = null, onType } = useSockets();

  const user: User | undefined = useMemo(() => {
    return userData.data
      ? {
          _id: userData.data.id,
          name: getFullName(userData.data),
          avatar: function () {
            return (
              <Avatar
                size={32}
                name={getFullName(userData.data)}
                color={theme.palette.primary[800]}
              />
            );
          },
        }
      : undefined;
  }, [userData.data]);

  const getNotViewedChatsCountByPharmacy = useCallback(async () => {
    const { count } =
      await unifiedCommsService.getNotViewedChatsCountByPharmacy(locationId);
    setCounts({ unread: count });
  }, [setCounts]);

  useEffect(() => {
    getNotViewedChatsCountByPharmacy();
  }, [locationId, patients, getNotViewedChatsCountByPharmacy]);

  useEffect(() => {
    void (async () => {
      const rawConversationsData: DirectMessagePatientDto[] =
        await unifiedCommsService.getAllPatientsWithConversationsByLocation(
          locationId,
        );

      const groupedConversations = groupConversationsByPatient(
        rawConversationsData,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          conversationsSorting.field,
          conversationsSorting.order,
          conversationsSorting.isDate,
        ),
      );
      const newViewedConversations = rawConversationsData
        .filter((conversation) => conversation.pharmacy_viewed_all_messages)
        .map((conversation) => conversation.conversation_id);

      setRawConversations(rawConversationsData);
      setPatients(groupedConversations);
      setViewedConversations(newViewedConversations);
    })();
  }, []);

  useEffect(() => {
    void (async () => {
      const userId = await AsyncStorage.getItem(StorageKeys.UserId);

      if (userId) {
        const filteredConversations =
          await unifiedCommsService.filterConversationList(locationId, {
            unread_by_pharmacy: baseFilter === BaseFilterValues.UNREAD,
            unread_by_patient: baseFilter === BaseFilterValues.UNREAD_BY_USER,
            includes_current_pharmacy_user: multiFilters.includes(
              MultiFilterValues.INCLUDES_ME,
            )
              ? userId
              : undefined,
            created_by_current_pharmacy_user: multiFilters.includes(
              MultiFilterValues.CREATED_BY_ME,
            )
              ? userId
              : undefined,
            is_most_recent_message_less_than_one_day: multiFilters.includes(
              MultiFilterValues.TODAY,
            ),
            is_most_recent_message_less_than_seven_days: multiFilters.includes(
              MultiFilterValues.LAST_SEVEN_DAYS,
            ),
          });
        const groupedConversations = groupConversationsByPatient(
          filteredConversations,
        ).sort((currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            conversationsSorting.field,
            conversationsSorting.order,
            conversationsSorting.isDate,
          ),
        );
        removeSelectedPatient();
        removeSelectedConversation();
        setPatients(groupedConversations);
      }
    })();
  }, [baseFilter, multiFilters]);

  useEffect(() => {
    (() => {
      const groupedConversations = [...patients].sort(
        (currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            conversationsSorting.field,
            conversationsSorting.order,
            conversationsSorting.isDate,
          ),
      );
      setPatients(groupedConversations);
    })();
  }, [conversationsSorting]);

  useEffect(() => {
    void (async () => {
      const templates: MessageTemplateDto[] =
        await unifiedCommsService.messageTemplateFindAll(locationId);
      setTemplates(templates);
    })();
  }, [locationId]);

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: IFile[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        const responseWriteUrl = await FileStorageService.writeUrl(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
        );

        await FileStorageService.uploadFile(file, responseWriteUrl.data.url);

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  async function composeAttachments(
    attachments: ClientAttachmentDto[] | undefined,
  ): Promise<IComposedAttachments> {
    const attachmentsPresent = Boolean(attachments && attachments.length);

    if (!attachmentsPresent) {
      return {
        files: [],
        images: [],
      };
    }

    async function getAttachmentUrl(attachment: ClientAttachmentDto) {
      const urlResponse = await FileStorageService.readUrl(
        LocationCategory.DirectMessage,
        locationId,
        attachment.stored_filename,
        pharmacyId,
      );
      return {
        ...attachment,
        url: urlResponse.data.url,
      };
    }

    // This function separates images from simple files.
    // Further (in ChatBox component) they will be handled and showed a bit differently.
    function separateImages(files: ClientAttachmentDto[]) {
      return files.reduce(
        (
          previousElement: ClientAttachmentDto[][],
          element: ClientAttachmentDto,
        ) => {
          const [images, files] = previousElement;
          return FileStorageService.isImage(element.name)
            ? [[...images, element], files]
            : [images, [...files, element]];
        },
        [[], []],
      );
    }

    const [images, files] = separateImages(attachments!);
    const urlImages = images.length
      ? await Promise.all(images.map(getAttachmentUrl))
      : [];

    return {
      files: files,
      images: urlImages,
    };
  }

  function validateMessage(message: TypedMessage) {
    let errorsCount = 0;

    if (!message.text) {
      setError(
        ERROR_NO_MESSAGE,
        ErrorStatus.ERROR,
        getText('message-is-required'),
      );
      errorsCount += 1;
    }
    if (message.text && message.text.length > MESSAGE_LIMIT) {
      setError(
        ERROR_MESSAGE_LIMIT_FIVE_THOUSAND,
        ErrorStatus.ERROR,
        getText('message-characters-limit-five-thousand'),
      );
      errorsCount += 1;
    }

    return errorsCount;
  }

  async function getMessages(
    locationPatientId: string,
    conversationId: string,
    limit?: number,
  ): Promise<GetMessagesResult> {
    const IMessages: IMessageExtended[] = [];

    const data = await Promise.all([
      unifiedCommsService.getAllMessagesByConversation(
        locationId,
        locationPatientId,
        conversationId,
        limit,
      ),
      unifiedCommsService.getConversation(
        pharmacyId,
        locationId,
        locationPatientId,
        conversationId,
      ),
    ]);

    const messages = data[0].messages as DirectMessageExtended[];
    const messagesCount: number = data[0].count;
    const conversation: DirectMessagePatientDto = data[1];
    const authors = conversation.all_authors
      ? conversation.all_authors
          .filter((author) => Boolean(author))
          .reduce(
            (accumulator: any, author: any) => ({
              ...accumulator,
              [author._id]: getFullName(author),
            }),
            {},
          )
      : {};

    for await (const message of messages) {
      const isOwner = user?._id === message.author_id;
      const companionName: string = authors[message.author_id];
      const attachments = message.attachments;
      const composedAttachments = await composeAttachments(attachments);

      IMessages.push({
        _id: message.id,
        user: isOwner
          ? user
          : {
              _id: message.author_id,
              avatar: function () {
                return (
                  <Avatar
                    size={32}
                    name={companionName}
                    color={theme.palette.gray[100]}
                  />
                );
              },
              name: companionName,
            },
        text: message.content,
        createdAt: new Date(message.created_at),
        attachments: composedAttachments,
      });
    }

    return {
      messages: IMessages,
      count: messagesCount,
    };
  }

  async function updateConversationStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    pharmacyViewedAllMessages: boolean,
  ) {
    await unifiedCommsService.updateUserViewedStatus(
      locationId,
      locationPatientId,
      conversationId,
      {
        patient_viewed_all_messages: patientViewedAllMessages,
        pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
      },
    );
    const unreadConversationsCopy = [...viewedConversations];
    const index = unreadConversationsCopy.indexOf(conversationId);

    if (pharmacyViewedAllMessages && index === -1) {
      setViewedConversations([...unreadConversationsCopy, conversationId]);
    } else if (!pharmacyViewedAllMessages && index > -1) {
      unreadConversationsCopy.splice(index, 1);
      setViewedConversations(unreadConversationsCopy);
    }

    getNotViewedChatsCountByPharmacy();
  }

  async function onSelectPatient(
    patientConversationMeta: DirectMessagePatientDto,
  ) {
    const newSelectedPatient =
      selectedPatient?.location_patient_id ===
      patientConversationMeta.location_patient_id
        ? undefined
        : patientConversationMeta;

    if (selectedMessages.length) {
      removeSelectedMessages();
    }

    if (!newSelectedPatient) {
      removeSelectedPatient();
      removeSelectedConversation();
      return;
    }

    const data = await Promise.all([
      PatientService.getPatientLocationRecord(
        locationId,
        newSelectedPatient.location_patient_id,
      ),
      unifiedCommsService.getConversationsByPatientId(
        locationId,
        patientConversationMeta.location_patient_id,
      ),
    ]);

    const { date_of_birth, phone, is_verified } = data[0];
    const conversations = data[1].sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          'updated_at',
          Order.DESC,
          true,
        ),
    );
    const firstConversation = conversations[0];

    addViewed(conversations);
    setSelectedPatient({
      ...newSelectedPatient,
      date_of_birth,
      phone,
      is_verified,
    });
    setMessagesGroups(conversations);

    await onSelectConversation(
      firstConversation.location_patient_id,
      firstConversation.id,
      firstConversation.patient_viewed_all_messages,
      firstConversation.pharmacy_viewed_all_messages,
    );
  }

  const onSend = async (typedMessage: TypedMessage) => {
    if (!selectedPatient || !user || !selectedConversation) {
      return;
    }

    const errorsCount = validateMessage(typedMessage);
    if (errorsCount) {
      return;
    }

    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      typedMessage.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      return;
    }

    const messageFromServer = await unifiedCommsService.addMessage(
      pharmacyId,
      locationId,
      selectedPatient.location_patient_id,
      selectedConversation,
      {
        author_id: user._id.toString(),
        author_type: AuthorType.Pharmacy,
        content: typedMessage.text,
        patient_viewed_all_messages: false,
        pharmacy_viewed_all_messages: true,
        attachments: filesUploadResult.filesData.length
          ? filesUploadResult.filesData
          : [],
      },
    );

    const composedAttachments = await composeAttachments(
      messageFromServer.attachments,
    );

    const newMessage: IMessageExtended = {
      _id: messageFromServer.id,
      createdAt: new Date(messageFromServer.created_at),
      user: typedMessage.user,
      text: typedMessage.text,
      attachments: composedAttachments,
    };

    if (Object.keys(messageFromServer).length > 0) {
      const conversationMessages = [...selectedMessages, newMessage];
      setSelectedMessages(conversationMessages);
      setMessagesPagination({
        ...messagesPagination,
        count: messagesPagination.count + 1,
      });
    }
  };

  function addViewed(conversations: ConversationDto[]) {
    const newViewed = [];
    const viewedMap = viewedConversations.reduce(
      (a, v) => ({ ...a, [v]: v }),
      {},
    );

    for (const conversation of conversations) {
      if (
        conversation.pharmacy_viewed_all_messages &&
        !(conversation.id in viewedMap)
      ) {
        newViewed.push(conversation.id);
      }
    }

    if (newViewed.length) {
      setViewedConversations([...viewedConversations, ...newViewed]);
    }
  }

  const onSelectConversation = async (
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    pharmacyViewedAllMessages: boolean,
  ) => {
    const isSameConversation = conversationId === selectedConversation;
    setLoading(LOADING_MESSAGES, true);

    if (selectedMessages.length) {
      removeSelectedMessages();
    }

    if (isSameConversation) {
      removeSelectedConversation();
      return;
    }

    setSelectedConversation(conversationId);
    const messagesResult = await getMessages(
      locationPatientId,
      conversationId,
      MESSAGES_COUNT_LIMIT,
    );
    setSelectedMessages(messagesResult.messages);
    setMessagesPagination({
      limit: MESSAGES_COUNT_LIMIT,
      count: messagesResult.count,
    });

    if (!pharmacyViewedAllMessages) {
      await updateConversationStatus(
        locationId,
        locationPatientId,
        conversationId,
        patientViewedAllMessages,
        true,
      );
    }
    setLoading(LOADING_MESSAGES, false);
  };

  const onMaskUnread = async (
    locationPatientId: string,
    conversationId: string,
    patientViewedAllMessages: boolean,
    pharmacyViewedAllMessages: boolean,
  ) => {
    await updateConversationStatus(
      locationId,
      locationPatientId,
      conversationId,
      patientViewedAllMessages,
      pharmacyViewedAllMessages,
    );
    removeSelectedConversation();

    const changedRawList = rawConversations.map((rawConversation) => {
      if (rawConversation.conversation_id === conversationId) {
        rawConversation.pharmacy_viewed_all_messages =
          pharmacyViewedAllMessages;
      }
      return rawConversation;
    });
    const groupedConversations = groupConversationsByPatient(
      changedRawList,
    ).sort((currentConversation, nextConversation) =>
      compare(
        currentConversation,
        nextConversation,
        conversationsSorting.field,
        conversationsSorting.order,
        conversationsSorting.isDate,
      ),
    );
    setRawConversations(changedRawList);
    setPatients(groupedConversations);
  };

  const onCancelModal = () => {
    closeNewChatModal();
    setInputFieldsData({});
  };

  const onConversationCreate = async (data: CreateConversationData) => {
    setLoading(LOADING_CONVERSATION_CREATING, true);
    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      data.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING_MODAL,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      setLoading(LOADING_CONVERSATION_CREATING, false);
      return;
    }

    const createConversationDto = {
      author_id: (user?._id ?? '') as string,
      author_type: AuthorType.Pharmacy,
      subject: data.subject,
      patient_viewed_all_messages: false,
      pharmacy_viewed_all_messages: true,
      content: data.message,
      attachments: filesUploadResult.filesData.length
        ? filesUploadResult.filesData
        : [],
    };

    await unifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      data.patient,
      createConversationDto,
    );

    setLoading(LOADING_CONVERSATION_CREATING, false);
    onCancelModal();

    if (selectedPatient) {
      removeSelectedPatient();
      removeSelectedConversation();
    }

    const rawConversationsData: DirectMessagePatientDto[] =
      await unifiedCommsService.getAllPatientsWithConversationsByLocation(
        locationId,
      );
    const groupedConversations = groupConversationsByPatient(
      rawConversationsData,
    ).sort((currentConversation, nextConversation) =>
      compare(
        currentConversation,
        nextConversation,
        conversationsSorting.field,
        conversationsSorting.order,
        conversationsSorting.isDate,
      ),
    );

    setRawConversations(rawConversationsData);
    setPatients(groupedConversations);
  };

  const onPatientFilter = (text: string) => {
    setPatientFilter(text);
    const regExp = new RegExp(text, 'i');
    const filtered = [...rawConversations].filter((conversation) =>
      regExp.test(getFullName(conversation)),
    );

    const groupedConversations = groupConversationsByPatient(filtered).sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          conversationsSorting.field,
          conversationsSorting.order,
          conversationsSorting.isDate,
        ),
    );

    setPatients(groupedConversations);
  };

  const loadMore = async () => {
    const newMessagesLimit = messagesPagination.limit + MESSAGES_COUNT_LIMIT;
    const conversation = messagesGroups.find(
      (group) => group.id === selectedConversation,
    );
    if (conversation && selectedConversation) {
      setLoading(LOADING_MESSAGES, true);
      const messagesResult = await getMessages(
        conversation.location_patient_id,
        selectedConversation,
        newMessagesLimit,
      );
      setMessagesPagination({
        limit: newMessagesLimit,
        count: messagesResult.count,
      });
      setSelectedMessages(messagesResult.messages);
      setLoading(LOADING_MESSAGES, false);
    }
  };

  const renderLoadMore = useCallback(() => {
    return (
      <Button
        hierarchy="pharmacy-secondary"
        size="small"
        logger={{ id: 'render-load-earlier' }}
        style={{
          marginTop: 4,
          marginBottom: 4,
          marginRight: 'auto',
          marginLeft: 'auto',
          width: 'fit-content',
        }}
        onPress={loadMore}
      >
        Load more
      </Button>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  }, [loadMore, loadingObject[LOADING_MESSAGES]?.isLoading]);

  return (
    <View style={styles.container}>
      <ErrorsContainer
        errorObject={errorObject}
        excludedErrors={EXCLUDED_ERRORS_INBOX}
      />
      <NewChatModal
        show={isOpenNewChatModal}
        onConversationCreate={onConversationCreate}
        onCancel={onCancelModal}
        recipient={selectedPatient}
        templates={templates}
        isConversationCreating={
          LOADING_CONVERSATION_CREATING in loadingObject &&
          loadingObject[LOADING_CONVERSATION_CREATING].isLoading
        }
      />
      <View style={styles.tabHeader}>
        <Text style={styles.headerText}>{getText('chat-messages')}</Text>
        <TextInput
          placeholder={getText('search-chats')}
          autoComplete="off"
          autoCapitalize="none"
          style={styles.headerSearch}
          mode="outlined"
          outlineColor={theme.palette.white}
          children={undefined}
        />
      </View>
      {rawConversations.length ? (
        <View style={styles.body}>
          <View
            style={[
              styles.conversationsContainer,
              Boolean(selectedPatient) && { width: CONVERSATIONS_TABS_WIDTH },
            ]}
          >
            <View style={{ flexDirection: 'column' }}>
              <View style={styles.actions}>
                <ToolbarSortingDropDown
                  value={conversationsSorting.value}
                  onChange={(option) => setConversationsSorting(option)}
                  options={sortingOptions}
                  size="min"
                  styles={{
                    control: {
                      fontSize: 13,
                    },
                    singleValue: {
                      color: theme.palette.gray[900],
                    },
                    dropdownIndicator: {
                      color: theme.palette.gray[900],
                    },
                  }}
                />
                <View style={styles.rightActions}>
                  <TouchableOpacity
                    onPress={notImplementedAlert}
                    disabled={false}
                  >
                    <ArrowUpFromLineIcon size={14} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={notImplementedAlert}
                    disabled={false}
                  >
                    <PlusInCircleIcon size={16} />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.searchSection}>
                <View style={styles.patientSearchIcon}>
                  <SearchIcon size={18} color={theme.palette.gray[400]} />
                </View>
                <TextInput
                  placeholder={getText('find-patient')}
                  autoComplete="off"
                  autoCapitalize="none"
                  style={[
                    styles.patientSearch,
                    Boolean(selectedPatient) && { width: '100%' },
                  ]}
                  mode="outlined"
                  outlineColor={theme.palette.white}
                  children={undefined}
                  onChangeText={onPatientFilter}
                  value={patientFilter}
                />
              </View>
            </View>
            <View
              style={{
                marginRight: theme.getSpacing(1) + theme.getSpacing(0.5),
              }}
            >
              {Boolean(patients.length) &&
                patients.map((patient, index) => (
                  <Fragment key={patient.location_patient_id}>
                    <TouchableOpacity
                      onPress={() => void onSelectPatient(patient)}
                      disabled={false}
                    >
                      <View
                        style={[
                          styles.patient,
                          index + 1 === patients.length && styles.isLast,
                        ]}
                      >
                        <PatientConversation
                          patient={patient}
                          isViewed={viewedConversations.includes(
                            patient.conversation_id,
                          )}
                          isSelected={
                            patient.location_patient_id ===
                            selectedPatient?.location_patient_id
                          }
                        />
                      </View>
                    </TouchableOpacity>
                  </Fragment>
                ))}
            </View>
          </View>
          {selectedPatient && (
            <View style={{ flex: 1 }}>
              <View style={styles.messagesHeader}>
                <View style={{ flexDirection: 'row' }}>
                  <Avatar
                    size={40}
                    name={getFullName(selectedPatient)}
                    color={theme.palette.gray[100]}
                  />
                  <View style={styles.userInfo}>
                    <Text>{getFullName(selectedPatient)}</Text>
                    <View style={{ flexDirection: 'row' }}>
                      {selectedPatient.date_of_birth ? (
                        <Text
                          style={{
                            marginRight:
                              theme.getSpacing(1) + theme.getSpacing(0.5),
                          }}
                        >
                          {formatDate(selectedPatient.date_of_birth)}
                        </Text>
                      ) : null}
                      {selectedPatient.phone ? (
                        <Text>{prettyFormat(selectedPatient.phone)}</Text>
                      ) : null}
                    </View>
                  </View>
                </View>
                <Button
                  hierarchy="pharmacy-primary"
                  size="small"
                  logger={{ id: 'new-subject-button' }}
                  style={{
                    height: theme.getSpacing(4) + theme.getSpacing(0.5),
                  }}
                  onPress={() => openNewChatModal()}
                >
                  {getText('new-subject')}
                </Button>
              </View>
              <VerificationAlert locationId={locationId} />
              <View style={styles.messagesBlock}>
                {user &&
                  messagesGroups.map((conversation) => (
                    <Fragment key={conversation.id}>
                      <ConversationBox
                        user={user}
                        messages={selectedMessages}
                        typingMember={typingMember}
                        onTyping={() => onType(conversation.id)}
                        isOpen={conversation.id === selectedConversation}
                        subject={conversation.subject}
                        onSendMessage={onSend}
                        isViewed={viewedConversations.includes(conversation.id)}
                        updatedAt={conversation.updated_at}
                        onHeaderClick={() =>
                          void onSelectConversation(
                            conversation.location_patient_id,
                            conversation.id,
                            conversation.patient_viewed_all_messages,
                            conversation.pharmacy_viewed_all_messages,
                          )
                        }
                        onMaskUnread={() =>
                          void onMaskUnread(
                            conversation.location_patient_id,
                            conversation.id,
                            conversation.patient_viewed_all_messages,
                            false,
                          )
                        }
                        renderLoadEarlier={renderLoadMore}
                        messagesCount={messagesPagination.count}
                        conversationId={conversation.id}
                      />
                    </Fragment>
                  ))}
              </View>
            </View>
          )}
        </View>
      ) : (
        <View style={styles.noMessages}>
          <MessageCircleIcon size={45} />
          <Text style={styles.noMessagesText}>{getText('no-messages')}</Text>
          <Text>{getText('add-mobile-number-send-message')}</Text>
          <Button
            hierarchy="pharmacy-secondary"
            size="small"
            logger={{ id: 'add-mobile-number-button' }}
            style={{ marginTop: theme.getSpacing(2) }}
            onPress={notImplementedAlert}
          >
            {getText('add-mobile-number')}
          </Button>
        </View>
      )}
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    margin: theme.getSpacing(4),
  },
  tabHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1) + theme.getSpacing(0.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  headerText: {
    ...theme.fonts.medium,
    fontSize: 26,
    marginRight: theme.getSpacing(4),
  },
  headerSearch: {
    backgroundColor: '#EAF1F4', // blue-gray
    height: 44,
    width: 320,
  },
  noMessages: {
    alignItems: 'center',
    marginTop: '15%',
  },
  noMessagesText: {
    ...theme.fonts.medium,
    fontSize: 20,
    marginTop: theme.getSpacing(1) + theme.getSpacing(0.5),
  },
  body: {
    flexDirection: 'row',
    marginTop: 12,
    width: '100%',
  },
  conversationsContainer: {
    width: '100%',
    flexDirection: 'column',
    marginRight: theme.getSpacing(1) + theme.getSpacing(0.5),
  },
  patient: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray[100],
    cursor: 'pointer',
  },
  isLast: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray[100],
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 46,
  },
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
  patientSearch: {
    height: 36,
    width: CONVERSATIONS_TABS_WIDTH,
    marginLeft: -(theme.getSpacing(4) + theme.getSpacing(1)),
    paddingLeft: theme.getSpacing(4) + theme.getSpacing(1),
  },
  patientSearchIcon: {
    zIndex: 1,
    marginTop: theme.getSpacing(1),
    marginLeft: theme.getSpacing(3),
  },
  messagesHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
  },
  messagesBlock: {
    width: '100%',
    height: '100%',
  },
  userInfo: {
    justifyContent: 'space-between',
    marginLeft: theme.getSpacing(1) + theme.getSpacing(0.5),
  },
}));
