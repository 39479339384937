import { IMessage, User } from 'react-native-gifted-chat';
import {
  DirectMessagePatientDto,
  MessageTemplateDto,
  ConversationDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { useInboxState } from './inbox-store';
import {
  BaseFilterValues,
  EmittedMessage,
  MultiFilterValues,
  SelectedPatientFields,
  EmittedConversation,
  MessagesPagination,
} from '../types';
import {
  PatientUserDto,
  PharmacyUserDto,
} from '@digitalpharmacist/users-service-client-axios';

export const setPatients = (patients: DirectMessagePatientDto[]) => {
  useInboxState.setState({ patients: patients });
};

export const setSelectedPatient = (
  selectedPatient: DirectMessagePatientDto & SelectedPatientFields,
) => {
  useInboxState.setState({ selectedPatient: selectedPatient });
};

export const removeSelectedPatient = () => {
  useInboxState.setState({ selectedPatient: null });
};

export const setSelectedConversation = (selectedConversation: string) => {
  useInboxState.setState({ selectedConversation: selectedConversation });
};

export const removeSelectedConversation = () => {
  useInboxState.setState({ selectedConversation: null });
};

export const setViewedConversations = (viewedConversations: string[]) => {
  useInboxState.setState({ viewedConversations: viewedConversations });
};

export const setConversationsSorting = (conversationsSorting: any) => {
  useInboxState.setState({ conversationsSorting: conversationsSorting });
};

export const setMessagesGroups = (messagesGroups: ConversationDto[]) => {
  useInboxState.setState({ messagesGroups: messagesGroups });
};

export const setSelectedMessages = (selectedMessages: IMessage[]) => {
  useInboxState.setState({ selectedMessages: selectedMessages });
};

export const removeSelectedMessages = () => {
  useInboxState.setState({ selectedMessages: [] });
};

export const setRawConversations = (
  rawConversations: DirectMessagePatientDto[],
) => {
  useInboxState.setState({ rawConversations: rawConversations });
};

export const openNewChatModal = () => {
  useInboxState.setState({ isOpenNewChatModal: true });
};

export const closeNewChatModal = () => {
  useInboxState.setState({ isOpenNewChatModal: false });
};

export const setPatientFilter = (text: string) => {
  useInboxState.setState({ patientFilter: text });
};

export const setTemplates = (templates: MessageTemplateDto[]) => {
  useInboxState.setState({ templates: templates });
};

export const setIncomeMessage = (
  message: EmittedMessage,
  author: PharmacyUserDto | PatientUserDto | undefined,
) => {
  useInboxState.setState((state) => {
    const isMessageNew = !state.selectedMessages.find(
      (item) => message.id === item._id,
    );

    const patientIndex = state.patients.findIndex((patient) => {
      return message.location_patient_id == patient.location_patient_id;
    });
    let newPatients: DirectMessagePatientDto[] = [];
    if (patientIndex > -1) {
      newPatients = [...state.patients];

      newPatients[patientIndex] = {
        ...newPatients[patientIndex],
        pharmacy_viewed_all_messages: !isMessageNew,
        most_recent_qualifying_message: message.content,
        most_recent_qualifying_message_date: message.created_at.toString(),
      };
    }

    let newSelectedMessages: IMessage[] = [];
    if (isMessageNew && state.selectedConversation == message.conversation_id) {
      const user: User = { _id: message.author_id };
      if (author) {
        user.name = author.firstName + ' ' + author.lastName;
      }
      const newMessage: IMessage = {
        _id: message.id,
        createdAt: new Date(message.created_at),
        text: message.content,
        user,
      };
      newSelectedMessages = [...state.selectedMessages, newMessage];
    }

    const newState = {
      ...state,
    };

    if (newSelectedMessages.length > 0) {
      newState.selectedMessages = newSelectedMessages;
    }

    if (newPatients.length > 0) {
      newState.patients = newPatients;
    }

    return newState;
  });
};

export const setNewConversation = (
  conversation: EmittedConversation,
  author: PharmacyUserDto | PatientUserDto | undefined,
) => {
  useInboxState.setState((state) => {
    const newState = { ...state };
    if (
      conversation.location_patient_id ===
      state.selectedPatient?.location_patient_id
    ) {
      const foundConversation = newState.messagesGroups.find(
        (item) => item.id === conversation.id,
      );

      if (newState.selectedConversation === conversation.id) {
        const user: User = { _id: conversation.author_id };
        if (author) {
          user.name = author.firstName + ' ' + author.lastName;
        }
        const message: IMessage = {
          _id: `new-message-${Date.now()}`,
          text: conversation.content,
          createdAt: new Date(conversation.content_created_at),
          user,
        };
        newState.selectedMessages = [...newState.selectedMessages, message];
      } else if (!foundConversation) {
        const newMessagesGroups = [
          { ...conversation },
          ...state.messagesGroups,
        ];
        newState.messagesGroups = newMessagesGroups;
      }
    }
    return newState;
  });
};

export const setNewPatient = (patient: DirectMessagePatientDto) => {
  useInboxState.setState((state) => {
    return {
      ...state,
      patients: [patient, ...state.patients],
    };
  });
};

export const setBaseFilter = (filter: BaseFilterValues) => {
  useInboxState.setState({
    baseFilter: filter,
  });
};

export const setMultiFilters = (filter: MultiFilterValues) => {
  const { multiFilters } = useInboxState.getState();

  if (filter === MultiFilterValues.NO_FILTERS) {
    useInboxState.setState({
      multiFilters: [],
    });
  } else if (multiFilters.includes(filter)) {
    useInboxState.setState({
      multiFilters: multiFilters.filter((filterName) => filter !== filterName),
    });
  } else {
    useInboxState.setState({
      multiFilters: [...multiFilters, filter],
    });
  }
};

export const disableFilters = () => {
  useInboxState.setState({ disabled: true });
};

export const enableFilters = () => {
  useInboxState.setState({ disabled: false });
};

export const setCounts = (count: { unread: number }) => {
  useInboxState.setState((state) => {
    const newState = { ...state };
    if (count.unread > 0) {
      return { ...newState, count };
    }
    return { ...state, count: {} };
  });
};

export const resetFilters = () => {
  useInboxState.setState({
    baseFilter: BaseFilterValues.ALL,
    multiFilters: [],
  });
};

export const setMessagesPagination = (pagination: MessagesPagination) => {
  useInboxState.setState({ messagesPagination: pagination });
};
