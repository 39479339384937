import { AxiosRequestConfig } from 'axios';
import { UNIFIED_COMMS_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as UnifiedCommsClient,
  DirectMessagePatientDto,
  ConversationDto,
  DirectMessageConversationFiltersDto,
  DirectMessageDto,
  UpdateUserViewedStatusDto,
  CreateConversationDto,
  MessageTemplateDto,
  AddDirectMessageDto,
  ConversationCountDto,
  DirectMessageExtendedResponseDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';

export interface IUnifiedCommsService {
  getAllPatientsWithConversationsByLocation(
    locationId: string,
  ): Promise<DirectMessagePatientDto[]>;
  filterConversationList(
    locationId: string,
    directMessageConversationFiltersDto: DirectMessageConversationFiltersDto,
  ): Promise<DirectMessagePatientDto[]>;
  getConversationsByPatientId(
    locationId: string,
    locationPatientId: string,
  ): Promise<ConversationDto[]>;
  getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessageExtendedResponseDto>;
  messageTemplateFindAll(locationId: string): Promise<MessageTemplateDto[]>;
  createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto>;
  getConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto>;
  updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto>;
  addMessage(
    pharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto>;
  getNotViewedChatsCountByPharmacy(
    locationId: string,
  ): Promise<ConversationCountDto>;
}

export class UnifiedCommsService
  extends BaseApiService
  implements IUnifiedCommsService
{
  private unifiedCommsServiceClient: UnifiedCommsClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.unifiedCommsServiceClient = new UnifiedCommsClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async getAllPatientsWithConversationsByLocation(
    locationId: string,
  ): Promise<DirectMessagePatientDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllPatientsWithConversationsByLocation(
        locationId,
      );
    return data;
  }

  async filterConversationList(
    locationId: string,
    directMessageConversationFiltersDto: DirectMessageConversationFiltersDto,
  ): Promise<DirectMessagePatientDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageFilterConversationList(
        locationId,
        directMessageConversationFiltersDto,
      );

    return data;
  }

  async getConversationsByPatientId(
    locationId: string,
    locationPatientId: string,
  ): Promise<ConversationDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllConversationsByPatient(
        locationId,
        locationPatientId,
      );
    return data;
  }

  async getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    limit?: number | undefined,
  ): Promise<DirectMessageExtendedResponseDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllMessagesByConversation(
        locationId,
        locationPatientId,
        conversationId,
        limit,
      );
    return data;
  }

  async updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageUpdateUserViewedStatus(
        locationId,
        locationPatientId,
        conversationId,
        updateUserViewedStatusDto,
      );
    return data;
  }

  async createConversation(
    pharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageCreateConversation(
        pharmacyId,
        locationId,
        locationPatientId,
        createConversationDto,
      );
    return data;
  }

  async messageTemplateFindAll(
    locationId: string,
  ): Promise<MessageTemplateDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.messageTemplateFindAll(locationId);
    return data;
  }

  async getConversation(
    pharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetConversation(
        pharmacyId,
        locationId,
        locationPatientId,
        conversationId,
      );
    return data;
  }

  async getNotViewedChatsCountByPharmacy(
    locationId: string,
  ): Promise<ConversationCountDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetNotViewedChatsCountByPharmacy(
        locationId,
      );
    return data;
  }

  async addMessage(
    pharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageAddMessage(
        pharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        addDirectMessageDto,
      );
    return data;
  }
}

export default new UnifiedCommsService(
  UNIFIED_COMMS_SERVICE_BASE_URL,
  undefined,
  true,
);
