import { AxiosRequestConfig } from 'axios';
import { BaseApiService } from 'assets/core/api';
import { USER_API_BASE_URL } from '../common/constants';
import { LoginForm, UserToken } from '../screens/login/login-actions';

export interface ILoginService {
  logIn(values: LoginForm): Promise<UserToken | null>;
}

export class LoginService extends BaseApiService implements ILoginService {
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
  ) {
    super(baseUrl, config as any, enableAuth);
  }

  async logIn(values: LoginForm): Promise<UserToken> {
    const { data } = await this.axiosInstance.post<UserToken>(
      `/user/pharmacist/login`,
      values,
    );

    return data;
  }
}

export default new LoginService(USER_API_BASE_URL, undefined, false);
