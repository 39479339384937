import create from 'zustand';
import { PharmacyUser } from '../api';

export interface UserState {
  data?: PharmacyUser;
  updateData: (data: UserState) => void;
  logout: () => void;
}

export const useUserState = create<UserState>((set) => ({
  data: undefined,
  updateData: (data: UserState) => {
    set({ ...data });
  },
  logout: () => {
    set({ data: undefined });
  },
}));

export const isUserAuthorized = (user: PharmacyUser | undefined) => {
  return (
    user?.id &&
    user?.email &&
    user?.privacyPolicyPromptAcceptanceTimestamp &&
    user.termsOfServicePromptAcceptanceTimestamp
  );
};
