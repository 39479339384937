import React, { FunctionComponent } from 'react';
import { Text } from '../../../../../packages/assets/components/text';
import { getText } from '../../../../../packages/assets/localization/localization';

export const AccessDenied: FunctionComponent<AccessDeniedProps> = (props) => {
  // No translation, because it's just for demo and will be replaced soon.
  return <Text>Access Denied</Text>;
};

export interface AccessDeniedProps {}
