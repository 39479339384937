import { View, TouchableOpacity } from 'react-native';
import { FunctionComponent } from 'react';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { ChatBox, ChatBoxProps } from './ChatBox';
import { makeStyles } from 'assets/theme';
import { IMessage, User } from 'react-native-gifted-chat';
import { formatMessageDate } from '../utils';

export const ConversationBox: FunctionComponent<ConversationBoxProps> = ({
  messages,
  user,
  isOpen,
  subject,
  isViewed,
  updatedAt,
  onHeaderClick,
  onMaskUnread,
  ...restProps
}) => {
  const styles = useStyles();

  messages.sort((a, b) => {
    return b.createdAt.valueOf() - a.createdAt.valueOf();
  });
  const lastMessage = messages[messages.length - 1];
  const isOwnMessage = messages.length
    ? lastMessage.user._id === user._id
    : true;

  const header = (
    <TouchableOpacity onPress={onHeaderClick}>
      <View style={styles.header}>
        <Text>{subject}</Text>
        <View style={styles.statusWrapper}>
          {isOpen && !isOwnMessage && isViewed && (
            <TouchableOpacity onPress={onMaskUnread}>
              <Text>{getText('mask-as-unread')}</Text>
            </TouchableOpacity>
          )}
          <Text style={styles.statusTime}>{formatMessageDate(updatedAt)}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  if (isOpen) {
    return (
      <View style={styles.wrapper}>
        {header}
        <ChatBox messages={messages} user={user} {...restProps} />
      </View>
    );
  } else {
    return <View style={styles.headerWrapper}>{header}</View>;
  }
};

interface ConversationBoxProps extends ChatBoxProps {
  messages: IMessage[];
  user: User;
  isOpen: boolean;
  subject: string;
  isViewed: boolean;
  updatedAt: string;
  conversationId: string;
  onHeaderClick: () => void;
  onMaskUnread: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: 440,
    width: '100%',
    height: '100%',
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 4,
    shadowOffsetWidth: 1,
    shadowOffsetHeight: 1,
    shadowRadius: 10,
    shadowOpacity: 0.2,
  },
  headerWrapper: {
    borderWidth: 1,
    borderColor: theme.palette.gray[200],
    borderRadius: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(1),
  },
  header: {
    ...theme.fonts.regular,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: theme.getSpacing(2),
    height: 40,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    color: theme.palette.gray[900],
  },
  statusWrapper: {
    flexDirection: 'row',
  },
  statusTime: {
    marginLeft: theme.getSpacing(2),
    color: theme.palette.gray[700],
  },
}));
