import { FunctionComponent, useState, useCallback, Fragment } from 'react';
import { View, TouchableOpacity, Image, Linking } from 'react-native';
import { v4 } from 'uuid';
import {
  GiftedChat,
  GiftedChatProps,
  Actions,
  ActionsProps,
} from 'react-native-gifted-chat';
import EmojiModal from 'react-native-emoji-modal';
import * as DocumentPicker from 'expo-document-picker';
import ParsedText from 'react-native-parsed-text';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import FileStorageService from '../../../api/FileStorageService';
import { getText } from 'assets/localization/localization';
import { PaperClipIcon, SmileIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { UserTyping } from '../hooks/useSockets';
import { TypedMessage } from '../types';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { Attachments } from './Attachments';
import { IFile } from '../../../api/types';
import {
  onUrlPress,
  onPhonePress,
  onEmailPress,
} from '../../../common/linking-utils';
import { ErrorStatus } from '../error-store/error-store';
import { setError } from '../error-store/error-actions';
import { useLoadingState } from '../loading-store/loading-store';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import {
  messageMock,
  LOADING_MESSAGES,
  ERROR_SELECT_FILES,
  ERROR_MAX_FILES_COUNT_TEN,
  PHONE_PATTERN,
  MESSAGE_LIMIT,
  EMOJI_CHARS_LENGTH,
} from '../data';
import { File, Image as IImage } from '../types';
import Footer from './Footer';

export const ChatBox: FunctionComponent<ChatBoxProps> = (props) => {
  const { locationId, pharmacyId } = useAppStateStore();
  const { loadingObject } = useLoadingState();
  const [text, setText] = useState<string>('');
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [attachments, setAttachments] = useState<IFile[]>([]);
  const styles = useStyles();
  const theme = useTheme();

  const getMessages = () => {
    if (loadingObject[LOADING_MESSAGES].isLoading) {
      return [messageMock];
    }

    return props.messages;
  };
  const messages = getMessages();

  const onSelectAttachments = async () => {
    try {
      const docsResult: DocumentPicker.DocumentResult =
        await DocumentPicker.getDocumentAsync({
          multiple: true,
        });

      if (docsResult.type === 'cancel' || !docsResult.output) {
        setError(
          ERROR_SELECT_FILES,
          ErrorStatus.ERROR,
          getText('selecting-files-wrong'),
        );
        return;
      }

      const files = Object.values(docsResult.output);

      if (files.length > 10) {
        setAttachments([]);
        setError(
          ERROR_MAX_FILES_COUNT_TEN,
          ErrorStatus.ERROR,
          getText('maxim-count-attachments', {
            count: 10,
          }),
        );
        return;
      }

      setAttachments(files);
    } catch (error) {
      console.error('Documents picking error: ', error);
    }
  };

  const onRemoveFile = (name: string) => {
    const filteredAttachments = attachments.filter(
      (attachment: IFile) => attachment.name !== name,
    );
    setAttachments(filteredAttachments);
  };

  const downloadAttachment = async (storedFilename: string) => {
    const urlResponse = await FileStorageService.readUrl(
      LocationCategory.DirectMessage,
      locationId,
      storedFilename,
      pharmacyId,
    );
    const url: string = urlResponse.data.url;
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.error(`No possibility to open url ${url}`);
    }
  };

  const renderBubble = useCallback(
    (args: any) => {
      if (loadingObject[LOADING_MESSAGES].isLoading) {
        return (
          <View style={{ flex: 1, marginBottom: 150, marginRight: 50 }}>
            <LoadingIndicator color={theme.palette.primary[700]} />
          </View>
        );
      }

      const isLeft = args.position === 'left';
      const isOwner = args.user._id === args.currentMessage.user._id;
      const attachments = args.currentMessage.attachments;
      const attachmentsPresent = Boolean(
        attachments?.images.length || attachments?.files.length,
      );

      return (
        <View
          style={[
            { flex: 1, alignItems: 'flex-end' },
            isLeft && { alignItems: 'flex-start' },
          ]}
        >
          <View
            style={[
              styles.bubbleContainer,
              isLeft && { marginLeft: 0, marginRight: 60 },
              isOwner && { backgroundColor: theme.palette.primary[700] },
            ]}
          >
            <View>
              <Text
                style={[
                  styles.bubbleText,
                  attachmentsPresent && { paddingBottom: theme.getSpacing(1) },
                  isOwner && { color: theme.palette.white },
                ]}
              >
                <ParsedText
                  parse={[
                    {
                      type: 'url',
                      style: { color: theme.palette.success[300] },
                      onPress: onUrlPress,
                    },
                    {
                      pattern: PHONE_PATTERN,
                      style: { color: theme.palette.success[300] },
                      onPress: onPhonePress,
                    },
                    {
                      type: 'email',
                      style: { color: theme.palette.success[300] },
                      onPress: onEmailPress,
                    },
                  ]}
                >
                  {args.currentMessage.text}
                </ParsedText>
              </Text>
              {attachmentsPresent && (
                <View style={{ flexDirection: 'column' }}>
                  {Boolean(attachments?.files.length) && (
                    <View style={{ flexDirection: 'column' }}>
                      {attachments.files.map((attachment: File) => (
                        <View style={styles.wrapper} key={attachment.id}>
                          <TouchableOpacity
                            onPress={() =>
                              void downloadAttachment(
                                attachment.stored_filename,
                              )
                            }
                          >
                            <Text style={styles.fileWrapper}>
                              {attachment.name}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      ))}
                    </View>
                  )}
                  {Boolean(attachments?.images.length) && (
                    <View style={styles.imagesContainer}>
                      {attachments.images.map((attachment: IImage) => (
                        <View style={styles.wrapper} key={attachment.id}>
                          <TouchableOpacity
                            onPress={() =>
                              void downloadAttachment(
                                attachment.stored_filename,
                              )
                            }
                          >
                            <Image
                              source={{ uri: attachment.url }}
                              style={styles.image}
                            />
                          </TouchableOpacity>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              )}
            </View>
          </View>
        </View>
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    [loadingObject[LOADING_MESSAGES]?.isLoading],
  );

  const onSmileClick = () => {
    setShowEmojis((previousState) => {
      return !previousState;
    });
  };

  const insertEmoji = (string: any, position: number, emoji: any) => {
    const stringLength = string.length;
    if (!position || !stringLength || stringLength === position) {
      return string + emoji;
    }

    const firstPart = string.slice(0, position);
    const lastPart = string.slice(-(stringLength - position));
    return firstPart + emoji + lastPart;
  };

  const onEmojiSelect = (emoji: any) => {
    if (text.length <= MESSAGE_LIMIT - EMOJI_CHARS_LENGTH) {
      setText(insertEmoji(text, cursorPosition, emoji));
    }
  };

  const ref = useOutsideClick(() => {
    setShowEmojis(false);
  });

  const renderActions = useCallback((props: Readonly<ActionsProps>) => {
    return (
      <>
        {/* TODO: This code will be implemented later.
        <Actions
          {...props}
          options={{
            [getText("text-formatting")]: notImplementedAlert,
          }}
          icon={() => (
            <AlignLeftIcon size={28} color={theme.palette.gray[500]} />
          )}
        /> */}
        <Actions
          {...props}
          icon={() => (
            <TouchableOpacity onPress={onSmileClick}>
              <SmileIcon size={28} color={theme.palette.gray[500]} />
            </TouchableOpacity>
          )}
        />
        <Actions
          {...props}
          icon={() => (
            <TouchableOpacity onPress={onSelectAttachments}>
              <PaperClipIcon size={28} color={theme.palette.gray[500]} />
            </TouchableOpacity>
          )}
        />
      </>
    );
  }, []);

  const handleOnPress = useCallback(() => {
    const user = props.user!;
    const textToSend = text.trim();

    if (props.onSendMessage) {
      props.onSendMessage({
        text: textToSend,
        user,
        attachments: attachments,
      });

      setAttachments([]);
      setText('');
    }
  }, [text, props.onSendMessage, attachments]);

  const onInputTextChanged = useCallback(
    (text: string) => {
      props.onTyping();
      setText(text);
    },
    [setText, props.onTyping],
  );

  const renderSend = useCallback(() => {
    return (
      <Button
        hierarchy="pharmacy-primary"
        size="small"
        logger={{ id: 'send-message' }}
        style={{ marginBottom: 4, marginRight: 4 }}
        onPress={handleOnPress}
      >
        {getText('send')}
      </Button>
    );
  }, [handleOnPress]);

  const renderFooter = useCallback(() => {
    return (
      <Footer
        typingMember={props.typingMember}
        text={text}
        conversationId={props.conversationId}
      />
    );
  }, [props.typingMember, text]);

  // Added key to force rerendering GiftedChat to keep messages array up to date,
  // since GiftedChat sometimes has a bug not updating it immediately.
  const key: number =
    props.messages && props.messages.length
      ? (props.messages[props.messages.length - 1].createdAt as Date).getTime()
      : new Date().getTime();

  const getLoadEarlier = (): boolean => {
    if (!messages || !messages.length) {
      return false;
    } else if (
      messages.length < props.messagesCount &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !loadingObject[LOADING_MESSAGES]?.isLoading
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fragment key={key}>
      <GiftedChat
        {...props}
        messages={messages}
        textInputProps={{
          ...props.textInputProps,
          onSelectionChange: (event: any) => {
            setCursorPosition(event.nativeEvent.selection.start);
          },
        }}
        text={text}
        maxInputLength={MESSAGE_LIMIT}
        renderBubble={renderBubble}
        renderActions={renderActions}
        renderSend={renderSend}
        onInputTextChanged={onInputTextChanged}
        renderFooter={renderFooter}
        loadEarlier={getLoadEarlier()}
        listViewProps={{
          disableVirtualization: true, // TODO: might be a temporary solution, because it is a deprecated property in FlatList
        }}
      />
      {showEmojis && (
        <View style={{ position: 'relative' }}>
          <View style={styles.emojiWrapper} ref={ref as any}>
            <EmojiModal onEmojiSelected={(emoji) => onEmojiSelect(emoji)} />
          </View>
        </View>
      )}
      {Boolean(attachments.length) && (
        <View
          style={{
            padding: theme.getSpacing(1),
            borderTopWidth: 1,
            borderTopColor: theme.palette.gray[200],
          }}
        >
          <Attachments files={attachments} onRemoveFile={onRemoveFile} />
        </View>
      )}
    </Fragment>
  );
};

export interface ChatBoxProps extends GiftedChatProps {
  typingMember: UserTyping | null;
  messagesCount: number;
  conversationId: string;
  onTyping: () => void;
  onSendMessage: (typedMessage: TypedMessage) => Promise<void>;
}

ChatBox.defaultProps = {
  placeholder: getText('type-your-message'),
  alwaysShowSend: true,
  showUserAvatar: true,
  scrollToBottom: true,
  messageIdGenerator: () => v4(),
};

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[100],
    padding: theme.getSpacing(1),
    borderRadius: 8,
  },
  bubbleText: {
    ...theme.fonts.regular,
    color: theme.palette.black,
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 44,
    zIndex: 10,
    backgroundColor: 'black',
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  image: {
    height: 100,
    width: 100,
    zIndex: 1,
  },
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fileWrapper: {
    color: theme.palette.cyan[200],
  },
  wrapper: {
    margin: theme.getSpacing(1),
  },
}));
