import axios, { AxiosRequestConfig } from 'axios';
import { FILE_STORAGE_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as FileStorageClient,
  LocationCategory,
} from '@digitalpharmacist/file-storage-service-client-axios';
import { IFile } from './types';

export class FileStorageService extends BaseApiService {
  private fileStorageServiceClient: FileStorageClient;
  public imageExtensions: string[] = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
  ];

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.fileStorageServiceClient = new FileStorageClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async uploadFile(file: IFile, path: string): Promise<any> {
    return await axios.put(path, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
  }

  async writeUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<any> {
    return await this.fileStorageServiceClient.fileStorageWriteUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
  }

  async readUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<any> {
    return await this.fileStorageServiceClient.fileStorageReadUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
  }

  getFileExtension(name: string): string {
    const attachmentParts = name.split('.');
    return attachmentParts[attachmentParts.length - 1];
  }

  isImage(fileName: string): boolean {
    const extension = this.getFileExtension(fileName);
    return this.imageExtensions.includes(extension);
  }
}

export default new FileStorageService(
  FILE_STORAGE_SERVICE_BASE_URL,
  undefined,
  true,
);
