import { PharmacyLocation } from 'api';
import create from 'zustand';
import { userApi } from '../api';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import pharmacyService from '../api/PharmacyService';
import roleService from '../api/RoleService';

export interface AppState {
  pharmacyId: string;
  locationId: string;
  stores: PharmacyLocation[];
  userLocations: PharmacyLocationDto[];
  getStores: () => Promise<void>;
  getUserLocations: (userId: string) => Promise<void>;
}

export const useAppStateStore = create<AppState>((set, get) => ({
  pharmacyId: '09653b42-b122-4ebb-88bc-408eb59f7fd5',
  locationId: '76c4e938-7e7f-48b5-acb7-3a6572b87f37', //Todo: Temporary until BLUES-2631 is done
  stores: [],
  userLocations: [],
  getStores: async () => {
    const pharmacyLocations = await userApi.userGetStores({
      pharmacyId: get().pharmacyId,
    });
    set({ stores: pharmacyLocations });
  },
  getUserLocations: async (userId: string) => {
    const allUserDetails =
      await roleService.userRoleGetUsersDetailsByPharmacyId(get().pharmacyId);

    const userDetails = allUserDetails.filter((it) => it.userId === userId);

    if (userDetails.length == 0) return;

    let pharmacyLocations = [];
    for (const location of userDetails[0].locations) {
      const pharmacyLocation = await pharmacyService.pharmacyLocationFindOne(
        location,
        get().pharmacyId,
      );
      pharmacyLocations.push(pharmacyLocation);
    }
    set({ userLocations: pharmacyLocations });
  },
}));
