import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { USER_API_BASE_URL } from '../common/constants';
import { PharmacyUser } from 'api';

export interface IPharmacyService {
  get(userId: string): Promise<PharmacyUser>;
}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  async get(userId: string): Promise<PharmacyUser> {
    const { data } = await this.axiosInstance.get(`/user/pharmacist/` + userId);
    const user = data as PharmacyUser;
    return user;
  }

  async getAll(): Promise<PharmacyUser[]> {
    const { data } = await this.axiosInstance.get(`/user/pharmacist/`);
    const users = (data as PharmacyUser[]).sort((userA, userB) => {
      if (
        `${userA.firstName} ${userA.lastName}` <
        `${userB.firstName} ${userB.lastName}`
      ) {
        return -1;
      }

      if (
        `${userA.firstName} ${userA.lastName}` >
        `${userB.firstName} ${userB.lastName}`
      ) {
        return 1;
      }

      return 0;
    });

    return users;
  }
}

export default new PharmacyService(USER_API_BASE_URL, undefined, true);
