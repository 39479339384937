import { BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { USER_API_BASE_URL } from '../common/constants';
import {
  ResetPasswordForm,
  ResetPasswordResponse,
} from '../screens/reset-password/reset-password-actions';

export interface IResetPasswordService {
  resetPassword(values: ResetPasswordForm): Promise<Object>;
  confirmPassword(values: ResetPasswordForm): Promise<void>;
}

export class ResetPasswordService
  extends BaseApiService
  implements IResetPasswordService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = false,
  ) {
    super(baseUrl, config as any, enableAuth);
  }
  async resetPassword(
    values: ResetPasswordForm,
  ): Promise<ResetPasswordResponse> {
    const { data } = await this.axiosInstance.post(
      `/user/pharmacist/password_reset`,
      values,
      {
        disableAuth: true,
      },
    );
    return data;
  }

  async confirmPassword(values: ResetPasswordForm): Promise<void> {
    const { data } = await this.axiosInstance.post(
      `/user/pharmacist/password_confirmation`,
      values,
      {
        disableAuth: true,
      },
    );
  }
}

export default new ResetPasswordService(USER_API_BASE_URL, undefined, true);
