"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Users-Service-DP2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoAuthApi = exports.NoAuthApiFactory = exports.NoAuthApiFp = exports.NoAuthApiAxiosParamCreator = exports.GeneralApi = exports.GeneralApiFactory = exports.GeneralApiFp = exports.GeneralApiAxiosParamCreator = exports.Role = exports.Gender = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.Gender = {
    Male: 'male',
    Female: 'female'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Role = {
    Corporate: 'CORPORATE',
    ItAdmin: 'IT_ADMIN',
    Owner: 'OWNER',
    Manager: 'MANAGER',
    Staff: 'STAFF',
    NoAccess: 'NO_ACCESS',
    InternalUser: 'INTERNAL_USER'
};
/**
 * GeneralApi - axios parameter creator
 * @export
 */
var GeneralApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/accountVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'pharmacyId', pharmacyId);
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'email', email);
                    localVarPath = "/user/patient/check_user/{pharmacy_id}/{email}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/confirmPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdPrivacyAndPolicyPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientIdPrivacyAndPolicyPatch', 'id', id);
                    localVarPath = "/user/patient/{id}/privacy_and_policy"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdTermsAndConditionPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientIdTermsAndConditionPatch', 'id', id);
                            localVarPath = "/user/patient/{id}/terms_and_condition"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userLogin, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Verifying phone phoneNumber
         * @param {string} authorization Authorization token
         * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPhoneNumberVerificationPost: function (authorization, verifyPhoneNumberRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'authorization' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientPhoneNumberVerificationPost', 'authorization', authorization);
                    localVarPath = "/user/patient/phoneNumberVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (authorization != null) {
                        localVarHeaderParameter['authorization'] = String(authorization);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(verifyPhoneNumberRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userRegisterDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userDetails, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resendConfirmationCode";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Sends an SMS with the confirmation code for the phone number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendPhoneNumberConfirmationCodePost: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/user/patient/resendPhoneNumberConfirmationCode";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (userDetails, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resetPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary sendPasswordResetLink
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientSendPasswordResetLinkPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/sendPasswordResetLink";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} userId String userId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdGet: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientUserIdGet', 'userId', userId);
                            localVarPath = "/user/patient/{userId}"
                                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update User Info
         * @param {string} userId String userId of the user to update
         * @param {UpdateUserRequest} [updateUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdPatch: function (userId, updateUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientUserIdPatch', 'userId', userId);
                            localVarPath = "/user/patient/{userId}"
                                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateUserRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all Pharmacist User Info
         * @param {string} [limit]
         * @param {string} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistGet: function (limit, offset, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdGet', 'id', id);
                    localVarPath = "/user/pharmacist/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyListByIds' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdListPost', 'pharmacyListByIds', pharmacyListByIds);
                    localVarPath = "/user/pharmacist/id_list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyListByIds, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdPrivacyAndPolicyPatch', 'id', id);
                    localVarPath = "/user/pharmacist/{id}/privacy_and_policy"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPharmacistIdTermsAndConditionPatch', 'id', id);
                            localVarPath = "/user/pharmacist/{id}/terms_and_condition"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacistUserLoginRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_confirmation";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(confirmPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {string} [email]
         * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (email, setPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(registerPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.GeneralApiAxiosParamCreator = GeneralApiAxiosParamCreator;
/**
 * GeneralApi - functional programming interface
 * @export
 */
var GeneralApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.GeneralApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.healthCheckGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientAccountVerificationPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientConfirmPasswordPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdPrivacyAndPolicyPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientIdPrivacyAndPolicyPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdTermsAndConditionPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientIdTermsAndConditionPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientLoginPost(userLogin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPasswordSetPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Verifying phone phoneNumber
         * @param {string} authorization Authorization token
         * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPhoneNumberVerificationPost: function (authorization, verifyPhoneNumberRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPhoneNumberVerificationPost(authorization, verifyPhoneNumberRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientRegisterPost(userRegisterDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userDetails, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResendConfirmationCodePost(userDetails, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Sends an SMS with the confirmation code for the phone number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendPhoneNumberConfirmationCodePost: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResendPhoneNumberConfirmationCodePost(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (userDetails, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResetPasswordPost(userDetails, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary sendPasswordResetLink
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientSendPasswordResetLinkPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientSendPasswordResetLinkPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} userId String userId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update User Info
         * @param {string} userId String userId of the user to update
         * @param {UpdateUserRequest} [updateUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdPatch: function (userId, updateUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientUserIdPatch(userId, updateUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all Pharmacist User Info
         * @param {string} [limit]
         * @param {string} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistGet: function (limit, offset, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistGet(limit, offset, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdListPost(pharmacyListByIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdPrivacyAndPolicyPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdTermsAndConditionPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistLoginPost(pharmacistUserLoginRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordConfirmationPost(confirmPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordResetPost(resetPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {string} [email]
         * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (email, setPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordSetPost(email, setPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.GeneralApiFp = GeneralApiFp;
/**
 * GeneralApi - factory interface
 * @export
 */
var GeneralApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.GeneralApiFp)(configuration);
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return localVarFp.healthCheckGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return localVarFp.userPatientAccountVerificationPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return localVarFp.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (body, options) {
            return localVarFp.userPatientConfirmPasswordPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdPrivacyAndPolicyPatch: function (id, options) {
            return localVarFp.userPatientIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdTermsAndConditionPatch: function (id, options) {
            return localVarFp.userPatientIdTermsAndConditionPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return localVarFp.userPatientLoginPost(userLogin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return localVarFp.userPatientPasswordSetPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Verifying phone phoneNumber
         * @param {string} authorization Authorization token
         * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPhoneNumberVerificationPost: function (authorization, verifyPhoneNumberRequest, options) {
            return localVarFp.userPatientPhoneNumberVerificationPost(authorization, verifyPhoneNumberRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return localVarFp.userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userDetails, options) {
            return localVarFp.userPatientResendConfirmationCodePost(userDetails, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Sends an SMS with the confirmation code for the phone number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendPhoneNumberConfirmationCodePost: function (options) {
            return localVarFp.userPatientResendPhoneNumberConfirmationCodePost(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (userDetails, options) {
            return localVarFp.userPatientResetPasswordPost(userDetails, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary sendPasswordResetLink
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientSendPasswordResetLinkPost: function (body, options) {
            return localVarFp.userPatientSendPasswordResetLinkPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} userId String userId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdGet: function (userId, options) {
            return localVarFp.userPatientUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update User Info
         * @param {string} userId String userId of the user to update
         * @param {UpdateUserRequest} [updateUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdPatch: function (userId, updateUserRequest, options) {
            return localVarFp.userPatientUserIdPatch(userId, updateUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all Pharmacist User Info
         * @param {string} [limit]
         * @param {string} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistGet: function (limit, offset, options) {
            return localVarFp.userPharmacistGet(limit, offset, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return localVarFp.userPharmacistIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return localVarFp.userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return localVarFp.userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return localVarFp.userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return localVarFp.userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordConfirmationPost(confirmPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordResetPost(resetPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {string} [email]
         * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (email, setPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordSetPost(email, setPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return localVarFp.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.GeneralApiFactory = GeneralApiFactory;
/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
var GeneralApi = /** @class */ (function (_super) {
    __extends(GeneralApi, _super);
    function GeneralApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.healthCheckGet = function (options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).healthCheckGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Confirm Registration - Verify Email / sms
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientAccountVerificationPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientAccountVerificationPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Checks if the patient exists in dp1 and dp2
     * @param {string} pharmacyId String pharmacyId of the pharmacist user
     * @param {string} email String email of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientCheckUserPharmacyIdEmailGet = function (pharmacyId, email, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientConfirmPasswordPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientConfirmPasswordPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientIdPrivacyAndPolicyPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientIdTermsAndConditionPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientIdTermsAndConditionPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {UserLogin} [userLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientLoginPost = function (userLogin, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientLoginPost(userLogin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Setting the password and creating the record in database
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientPasswordSetPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientPasswordSetPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Verifying phone phoneNumber
     * @param {string} authorization Authorization token
     * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientPhoneNumberVerificationPost = function (authorization, verifyPhoneNumberRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientPhoneNumberVerificationPost(authorization, verifyPhoneNumberRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Registration - Creates a patient user, returns a userId
     * @param {UserRegisterDto} [userRegisterDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientRegisterPost = function (userRegisterDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resend Confirmation Code
     * @param {UserDetails} [userDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientResendConfirmationCodePost = function (userDetails, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientResendConfirmationCodePost(userDetails, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Sends an SMS with the confirmation code for the phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientResendPhoneNumberConfirmationCodePost = function (options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientResendPhoneNumberConfirmationCodePost(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary INIT Reset Password, causes an email with code/link to be sent to user
     * @param {UserDetails} [userDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientResetPasswordPost = function (userDetails, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientResetPasswordPost(userDetails, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary sendPasswordResetLink
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientSendPasswordResetLinkPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientSendPasswordResetLinkPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get User Dto
     * @param {string} userId String userId of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update User Info
     * @param {string} userId String userId of the user to update
     * @param {UpdateUserRequest} [updateUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientUserIdPatch = function (userId, updateUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientUserIdPatch(userId, updateUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all Pharmacist User Info
     * @param {string} [limit]
     * @param {string} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistGet = function (limit, offset, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistGet(limit, offset, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Pharmacist User Info
     * @param {string} id String userId of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdGet = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting pharmacist users\' information by list of user ids
     * @param {PharmacyListByIds} pharmacyListByIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdListPost = function (pharmacyListByIds, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdPrivacyAndPolicyPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdTermsAndConditionPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistLoginPost = function (pharmacistUserLoginRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password with confirmationCode too
     * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordConfirmationPost = function (confirmPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordConfirmationPost(confirmPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Reset the pharmacist user\'s password
     * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordResetPost = function (resetPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordResetPost(resetPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password
     * @param {string} [email]
     * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordSetPost = function (email, setPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordSetPost(email, setPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Register the pharmacist user
     * @param {boolean} [email]
     * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistRegisterPost = function (email, registerPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GeneralApi;
}(base_1.BaseAPI));
exports.GeneralApi = GeneralApi;
/**
 * NoAuthApi - axios parameter creator
 * @export
 */
var NoAuthApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/accountVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'pharmacyId', pharmacyId);
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'email', email);
                    localVarPath = "/user/patient/check_user/{pharmacy_id}/{email}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/confirmPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userLogin, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userRegisterDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userDetails, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resendConfirmationCode";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (userDetails, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resetPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userDetails, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get all Pharmacist User Info
         * @param {string} [limit]
         * @param {string} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistGet: function (limit, offset, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdGet', 'id', id);
                    localVarPath = "/user/pharmacist/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyListByIds' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdListPost', 'pharmacyListByIds', pharmacyListByIds);
                    localVarPath = "/user/pharmacist/id_list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyListByIds, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdPrivacyAndPolicyPatch', 'id', id);
                    localVarPath = "/user/pharmacist/{id}/privacy_and_policy"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPharmacistIdTermsAndConditionPatch', 'id', id);
                            localVarPath = "/user/pharmacist/{id}/terms_and_condition"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacistUserLoginRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_confirmation";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(confirmPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {string} [email]
         * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (email, setPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(registerPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.NoAuthApiAxiosParamCreator = NoAuthApiAxiosParamCreator;
/**
 * NoAuthApi - functional programming interface
 * @export
 */
var NoAuthApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.NoAuthApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.healthCheckGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientAccountVerificationPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientConfirmPasswordPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientLoginPost(userLogin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPasswordSetPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientRegisterPost(userRegisterDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userDetails, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResendConfirmationCodePost(userDetails, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (userDetails, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResetPasswordPost(userDetails, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get all Pharmacist User Info
         * @param {string} [limit]
         * @param {string} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistGet: function (limit, offset, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistGet(limit, offset, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdListPost(pharmacyListByIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdPrivacyAndPolicyPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdTermsAndConditionPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistLoginPost(pharmacistUserLoginRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordConfirmationPost(confirmPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordResetPost(resetPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {string} [email]
         * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (email, setPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordSetPost(email, setPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.NoAuthApiFp = NoAuthApiFp;
/**
 * NoAuthApi - factory interface
 * @export
 */
var NoAuthApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.NoAuthApiFp)(configuration);
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return localVarFp.healthCheckGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return localVarFp.userPatientAccountVerificationPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return localVarFp.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (body, options) {
            return localVarFp.userPatientConfirmPasswordPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return localVarFp.userPatientLoginPost(userLogin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return localVarFp.userPatientPasswordSetPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return localVarFp.userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userDetails, options) {
            return localVarFp.userPatientResendConfirmationCodePost(userDetails, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {UserDetails} [userDetails]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (userDetails, options) {
            return localVarFp.userPatientResetPasswordPost(userDetails, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get all Pharmacist User Info
         * @param {string} [limit]
         * @param {string} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistGet: function (limit, offset, options) {
            return localVarFp.userPharmacistGet(limit, offset, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return localVarFp.userPharmacistIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return localVarFp.userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return localVarFp.userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return localVarFp.userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return localVarFp.userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordConfirmationPost(confirmPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordResetPost(resetPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {string} [email]
         * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (email, setPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordSetPost(email, setPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return localVarFp.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.NoAuthApiFactory = NoAuthApiFactory;
/**
 * NoAuthApi - object-oriented interface
 * @export
 * @class NoAuthApi
 * @extends {BaseAPI}
 */
var NoAuthApi = /** @class */ (function (_super) {
    __extends(NoAuthApi, _super);
    function NoAuthApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.healthCheckGet = function (options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).healthCheckGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Confirm Registration - Verify Email / sms
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientAccountVerificationPost = function (body, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientAccountVerificationPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Checks if the patient exists in dp1 and dp2
     * @param {string} pharmacyId String pharmacyId of the pharmacist user
     * @param {string} email String email of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientCheckUserPharmacyIdEmailGet = function (pharmacyId, email, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientConfirmPasswordPost = function (body, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientConfirmPasswordPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {UserLogin} [userLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientLoginPost = function (userLogin, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientLoginPost(userLogin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Setting the password and creating the record in database
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientPasswordSetPost = function (body, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientPasswordSetPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Registration - Creates a patient user, returns a userId
     * @param {UserRegisterDto} [userRegisterDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientRegisterPost = function (userRegisterDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resend Confirmation Code
     * @param {UserDetails} [userDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientResendConfirmationCodePost = function (userDetails, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientResendConfirmationCodePost(userDetails, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary INIT Reset Password, causes an email with code/link to be sent to user
     * @param {UserDetails} [userDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientResetPasswordPost = function (userDetails, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientResetPasswordPost(userDetails, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get all Pharmacist User Info
     * @param {string} [limit]
     * @param {string} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistGet = function (limit, offset, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistGet(limit, offset, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Pharmacist User Info
     * @param {string} id String userId of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdGet = function (id, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting pharmacist users\' information by list of user ids
     * @param {PharmacyListByIds} pharmacyListByIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdListPost = function (pharmacyListByIds, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdPrivacyAndPolicyPatch = function (id, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdTermsAndConditionPatch = function (id, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistLoginPost = function (pharmacistUserLoginRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password with confirmationCode too
     * @param {ConfirmPasswordPharmacistUserRequest} [confirmPasswordPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordConfirmationPost = function (confirmPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordConfirmationPost(confirmPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Reset the pharmacist user\'s password
     * @param {ResetPasswordPharmacistUserRequest} [resetPasswordPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordResetPost = function (resetPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordResetPost(resetPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password
     * @param {string} [email]
     * @param {SetPasswordPharmacistUserRequest} [setPasswordPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordSetPost = function (email, setPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordSetPost(email, setPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Register the pharmacist user
     * @param {boolean} [email]
     * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistRegisterPost = function (email, registerPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return NoAuthApi;
}(base_1.BaseAPI));
exports.NoAuthApi = NoAuthApi;
