import { UserRoleDto } from '@digitalpharmacist/role-service-client-axios';

// This guard checks if a user has an "INTERNAL USER" role. Returns true if yes and false otherwise.
export const InternalUserGuard = (
  userRoles?: UserRoleDto[],
): Promise<boolean> =>
  new Promise((resolve) => {
    if (userRoles) {
      const isInternalUser = !!userRoles.find(
        (userRole) => userRole.role === 'INTERNAL_USER',
      );

      resolve(isInternalUser);
    } else {
      resolve(false);
    }
  });
