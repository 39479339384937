import { logError, logEvent } from 'assets/logging/logger';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { useLoginState } from './login-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import { PharmacyUser } from '../../api';
import { ampli } from '../../common/ampliPharmacist';
import LoginService from '../../api/LoginService';
import roleService from '../../api/RoleService';
import pharmacyService from '../../api/PharmacyService';

export const login = async (values: LoginForm): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useLoginState.setState({ error: undefined, status: 'loading' });

  try {
    values.pharmacyId = pharmacyId;
    const response = await LoginService.logIn(values);
    const user = response.userInfo;
    await AsyncStorage.setItem(
      StorageKeys.AccessToken,
      response?.accessToken ?? '',
    );
    useLoginState.setState({ status: 'success' });
    await AsyncStorage.setItem(StorageKeys.UserId, user.id);
    useUserState.setState({ data: user });
    ampli.userLogin({
      pharmacy_id: values.pharmacyId,
      status: 'Logged In',
      method: 'lumistry',
    });
    const allUserDetails =
      await roleService.userRoleGetUsersDetailsByPharmacyId(pharmacyId);

    const userDetails = allUserDetails.filter((it) => it.userId === user.id);

    if (userDetails.length == 0) return;

    let pharmacyLocations = [];
    for (const location of userDetails[0].locations) {
      const pharmacyLocation = await pharmacyService.pharmacyLocationFindOne(
        location,
        pharmacyId,
      );
      pharmacyLocations.push(pharmacyLocation);
    }
    useAppStateStore.setState({ userLocations: pharmacyLocations });
    if (pharmacyLocations.length === 1) {
      useAppStateStore.setState({ locationId: pharmacyLocations[0].id });
    }
  } catch (e) {
    logEvent('login_failed', { email: values.email });
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    AsyncStorage.clear();
    useUserState.setState({
      data: undefined,
    });
  } catch (error: any) {
    logError(error);
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: PharmacyUser;
}
