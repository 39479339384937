import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { Button } from 'assets/components/button';
import { makeStyles, useTheme } from 'assets/theme';
import { View, useWindowDimensions } from 'react-native';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { CheckboxField } from 'assets/components/checkbox';
import { ScrollView } from 'react-native';
import {
  CreateTaskDto,
  TaskPriority,
  TaskStatus,
  TaskTypeDto,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { useTaskModalState } from './task-modal-store';
import {
  createNewTask,
  deleteTask,
  getModalFormOptions,
  updateShowModalState,
  updateTask,
} from './task-modal-actions';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  WeeklyInterval,
  ReoccurrenceField,
  OccurrenceForm,
  MonthlyInterval,
} from './ReoccurrenceField';
import {
  RecurrenceTypes,
  getRecurrenceRuleString,
  parseRecurrenceRuleString,
} from '../../common/recurrence-rules-utils';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { PharmacyUser } from '../../api';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { zIndexAuto } from '../../common/theme';
import { useTaskSettingsState } from '../../screens/settings/tasks/task-settings-store';
import {
  UserOptionTemplate,
  UserSingleValueTemplate,
  AssigneePlaceholderTemplate,
  TypeOptionTemplate,
  TypeSingleValueTemplate,
  TypePlaceholderTemplate,
} from '../../components/advanced-dropdown/templates/advanced-drop-down-field.templates';
import { getTomorrowDate } from './task-modal.utils';
import { Tooltip } from '../../components/Tooltip';

interface TaskForm extends CreateTaskDto {
  weekly_occurrence: OccurrenceForm;
  monthly_occurrence: OccurrenceForm;
  assignee: PharmacyUser | undefined;
  type: TaskTypeDto | null;
}

export const defaultWeeklyOccurrence = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  endDate: null,
  frequency: WeeklyInterval.EveryWeek,
  showOnGrid: false,
};

export const defaultMonthlyOccurrence = {
  day: undefined,
  endDate: null,
  frequency: MonthlyInterval.EveryMonth,
  showOnGrid: false,
};

const initialFormValue = {
  status: TaskStatus.Unresolved,
  summary: '',
  description: '',
  recurrence_rule: 'oneTime',
  visibility: TaskVisibility.Location,
  weekly_occurrence: defaultWeeklyOccurrence,
  monthly_occurrence: defaultMonthlyOccurrence,
  due_date: undefined,
  priority: TaskPriority.Medium,
  flagged: false,
  notes: '',
};

export const TaskModal: FunctionComponent<
  PropsWithChildren<TaskModalProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const openModal = () => {
    methods.reset();
    updateShowModalState(true);
  };

  const { height: viewportHeight } = useWindowDimensions();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { editingTask, editTaskData, showModal, status, assigneeOptions } =
    useTaskModalState();
  const { taskTypes } = useTaskSettingsState();

  const locationId = useAppStateStore.getState().locationId;

  const methods = useForm<TaskForm>({
    defaultValues: initialFormValue,
  });
  const { data: userData } = useUserState();

  const isUserCreatedTask =
    !editingTask || userData?.id === editTaskData?.created_by_user_id;

  const handleSubmit = async () => {
    let formValue = methods.getValues();
    const calculatedRecurrenceRule = getRecurrenceRuleString(
      formValue.recurrence_rule as RecurrenceTypes,
      formValue.recurrence_rule == 'monthly'
        ? formValue.monthly_occurrence
        : formValue.weekly_occurrence,
    );

    const assigned_user_id =
      formValue.visibility === TaskVisibility.Personal
        ? userData?.id
        : formValue.assignee?.id;

    formValue = {
      ...formValue,
      assigned_user_id,
      recurrence_rule: calculatedRecurrenceRule,
      task_type_id: formValue.type?.id || null,
      visibility_entity_id: editTaskData?.visibility_entity_id ?? locationId,
      created_by_user_id: editTaskData?.created_by_user_id ?? userData?.id,
    };

    if (editingTask && editTaskData) {
      updateTask(editTaskData?.id, formValue);
    } else {
      createNewTask(formValue);
    }

    closeModal();
  };

  const closeModal = () => {
    updateShowModalState(false);
    methods.reset();
  };

  useEffect(() => {
    if (!assigneeOptions?.length || !taskTypes?.length) {
      getModalFormOptions();
    }
  }, []);

  useEffect(() => {
    if (editTaskData) {
      const taskForm: TaskForm = {
        ...editTaskData,
        recurrence_rule: 'oneTime',
        weekly_occurrence: defaultWeeklyOccurrence,
        monthly_occurrence: defaultMonthlyOccurrence,
        assignee: assigneeOptions.find(
          (assignee) => assignee.id === editTaskData.assigned_user_id,
        ),
      };

      methods.reset({
        ...taskForm,
        ...parseRecurrenceRuleString(editTaskData.recurrence_rule),
      });
    } else {
      methods.reset(initialFormValue);
    }
  }, [editTaskData]);

  const watchRecurrenceRule = methods.watch('recurrence_rule');
  const watchVisibility = methods.watch('visibility');

  useEffect(() => {
    const isPersonal = watchVisibility === TaskVisibility.Personal;
    const assignee = methods.getValues('assignee');

    // If the visibility is set to personal we automatically fill in the assignee input with the current user data
    if (isPersonal && assignee?.id !== userData?.id) {
      methods.setValue('assignee', userData);
    }
  }, [watchVisibility]);

  return (
    <>
      <Button
        hierarchy="pharmacy-primary"
        size="small"
        logger={{ id: 'new-task-button' }}
        style={{
          margin: theme.getSpacing(2),
        }}
        onPress={() => openModal()}
      >
        New task
      </Button>
      {editTaskData && (
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => {
            setShowDeleteConfirmation(false);
            deleteTask(editTaskData?.id);
          }}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={`Are you sure you want to delete the task: "${editTaskData?.summary}"?`}
        />
      )}

      <Modal
        title={`${editingTask ? 'Edit' : 'New'} Task`}
        size="lg"
        deleteButtonProps={
          editingTask
            ? {
                hierarchy: 'tertiary-gray',
                onPress: () => setShowDeleteConfirmation(true),
                logger: { id: 'task-delete-button-modal' },
                pinLeft: true,
              }
            : undefined
        }
        cancelButtonProps={{
          onPress: () => closeModal(),
          logger: { id: 'task-cancel-button-modal' },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          hierarchy: 'pharmacy-primary',
          disabled: status == 'loading',
          logger: { id: 'task-ok-button-modal' },
        }}
        show={showModal}
      >
        <ScrollView style={{ maxHeight: viewportHeight * 0.75 }}>
          {status == 'loading' && <LoadingOverlay></LoadingOverlay>}
          <Form methods={methods}>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  name="status"
                  disabled={status === 'loading'}
                  values={[
                    { text: 'Unresolved', value: TaskStatus.Unresolved },
                    { text: 'Resolved', value: TaskStatus.Resolved },
                    { text: 'In Progress', value: TaskStatus.InProgress },
                    { text: 'On Hold', value: TaskStatus.OnHold },
                  ]}
                  isHorizontal
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <View style={styles.halfWidth}>
                  <AdvancedDropDownField
                    name="type"
                    label="Type"
                    isMulti={false}
                    options={taskTypes}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    getOptionValue={(optionValue) => optionValue.id}
                    getOptionLabel={(optionValue) => optionValue.title}
                    optionTemplate={TypeOptionTemplate}
                    singleValueTemplate={TypeSingleValueTemplate}
                    placeholderTemplate={TypePlaceholderTemplate}
                  />
                </View>
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.zIndex}>
              <Form.Column>
                <View
                  style={styles.halfWidth}
                  nativeID="task-modal-assignee-disabled-tooltip"
                >
                  <AdvancedDropDownField
                    name="assignee"
                    label="Assignee *"
                    isDisabled={watchVisibility === TaskVisibility.Personal}
                    isMulti={false}
                    options={assigneeOptions}
                    menuPortalTarget={document.body}
                    getOptionValue={(optionValue) => optionValue.id}
                    getOptionLabel={(optionValue) =>
                      `${optionValue.firstName} ${optionValue.lastName}`
                    }
                    isSearchable
                    isClearable={true}
                    optionTemplate={UserOptionTemplate}
                    singleValueTemplate={UserSingleValueTemplate}
                    placeholderTemplate={AssigneePlaceholderTemplate}
                    rules={{
                      required: 'Assignee is required',
                    }}
                  />
                </View>
                <Tooltip
                  place="bottom"
                  text="Personal tasks can be assigned only to the owner/creator of the task"
                  anchorId={
                    watchVisibility === TaskVisibility.Personal
                      ? `task-modal-assignee-disabled-tooltip`
                      : undefined
                  }
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.label}>Summary *</Text>
                <TextField
                  placeholder="Summary"
                  name="summary"
                  rules={{
                    required: 'Summary is required',
                  }}
                  disabled={status === 'loading'}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.label}>Description</Text>
                <TextField
                  placeholder="Description"
                  name="description"
                  type="text"
                  multiline={true}
                  numberOfLines={3}
                />
              </Form.Column>
            </Form.Row>
            <View style={styles.divider}></View>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  label="Recurrence"
                  name="recurrence_rule"
                  values={[
                    { text: 'One-Time', value: RecurrenceTypes.OneTime },
                    { text: 'Weekly', value: RecurrenceTypes.Weekly },
                    { text: 'Monthly', value: RecurrenceTypes.Monthly },
                  ]}
                  isHorizontal
                ></RadioButtonGroupField>
              </Form.Column>
            </Form.Row>

            {watchRecurrenceRule == RecurrenceTypes.OneTime && (
              <Form.Row style={{ zIndex: zIndexAuto }}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <DateTimePickerField
                    label="Start date"
                    timeLabel="Time"
                    name="due_date"
                    placeholder="Start date"
                    rules={{ required: 'Start date is required.' }}
                    minDate={new Date().toISOString()}
                  />
                </Form.Column>
              </Form.Row>
            )}

            {watchRecurrenceRule == RecurrenceTypes.Weekly && (
              <Form.Row style={{ zIndex: zIndexAuto }}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <ReoccurrenceField
                    name="weekly_occurrence"
                    type="weekly"
                    dueDateControlName="due_date"
                  ></ReoccurrenceField>
                </Form.Column>
              </Form.Row>
            )}

            {watchRecurrenceRule == RecurrenceTypes.Monthly && (
              <Form.Row style={{ zIndex: zIndexAuto }}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <ReoccurrenceField
                    name="monthly_occurrence"
                    type="monthly"
                    dueDateControlName="due_date"
                  ></ReoccurrenceField>
                </Form.Column>
              </Form.Row>
            )}

            <Form.Row>
              <Button
                hierarchy="tertiary-gray"
                mode="outlined"
                style={styles.todayButton}
                size="small"
                logger={{ id: 'task-edit--date--today' }}
                onPress={() =>
                  methods.setValue('due_date', new Date().toISOString())
                }
              >
                Today
              </Button>
              <>
                {watchRecurrenceRule == RecurrenceTypes.OneTime && (
                  <Button
                    hierarchy="tertiary-gray"
                    mode="outlined"
                    style={{ borderColor: 'transparent' }}
                    size="small"
                    logger={{ id: 'task-edit--date--tomorrow' }}
                    onPress={() =>
                      methods.setValue(
                        'due_date',
                        getTomorrowDate().toISOString(),
                      )
                    }
                  >
                    Tomorrow
                  </Button>
                )}
              </>
            </Form.Row>

            <View style={styles.divider}></View>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  name="priority"
                  label="Priority"
                  values={[
                    { text: 'High', value: TaskPriority.High },
                    { text: 'Medium', value: TaskPriority.Medium },
                    { text: 'Low', value: TaskPriority.Low },
                  ]}
                  isHorizontal
                ></RadioButtonGroupField>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <CheckboxField name="flagged" label="Mark as flagged" />
            </Form.Row>
            <View style={styles.divider}></View>
            {isUserCreatedTask && (
              <>
                <Form.Row>
                  <Form.Column>
                    <RadioButtonGroupField
                      name="visibility"
                      label="Visibility"
                      values={[
                        {
                          text: 'Team',
                          value: TaskVisibility.Location,
                          description: 'It will be viewed by everybody',
                        },
                        {
                          text: 'Personal',
                          value: TaskVisibility.Personal,
                          description:
                            'It will be viewed by you and the assignee (if assigned)',
                        },
                      ]}
                    ></RadioButtonGroupField>
                  </Form.Column>
                </Form.Row>
                <View style={styles.divider}></View>
              </>
            )}
            <Form.Row>
              <Form.Column>
                <Text style={styles.label}>Notes</Text>
                <TextField
                  placeholder="Notes"
                  name="notes"
                  type="text"
                  multiline={true}
                  numberOfLines={3}
                />
              </Form.Column>
            </Form.Row>
          </Form>
        </ScrollView>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    overflow: 'scroll',
  },
  divider: {
    height: 1,
    width: '100%',
    marginVertical: theme.getSpacing(4),
    backgroundColor: theme.palette.gray[200],
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
  halfWidth: {
    width: '50%',
  },
  todayButton: {
    borderColor: 'transparent',
    marginLeft: theme.getSpacing(1),
  },
  zIndex: {
    zIndex: 1,
  },
}));

interface TaskModalProps {}

export default TaskModal;
