import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerNavigationProp,
} from '@react-navigation/drawer';
import Forms from '../screens/forms/Forms';
import Submissions from '../screens/forms/Submissions';
import { Icon } from 'assets/components/icon';
import { NewFormModal } from '../forms/new-form-modal/NewFormModal';
import { FormsImportStepper } from '../forms/forms-import-stepper/FormsImportStepper';
import { ViewSubmission } from '../screens/forms/ViewSubmission';
import { DocumentIcon, TrayIcon } from 'assets/icons';
import { EditForm } from '../screens/forms/EditForm';
import { useTheme } from '../../../../packages/assets/theme';
import { navigationDrawerStyle } from '../common/theme';
import RoleService from '../api/RoleService';
import { useUserState } from '../store/user-store';
import { UserRoleDto } from '@digitalpharmacist/role-service-client-axios';
import { InternalUserGuard } from '../common/guards/internal-user-guard';
import { LoadingRouteGuard } from '../common/LoadingRouteGuard';
import { AccessDenied } from '../forms/access-denied/AccessDenied';

const DrawerNavigator = createDrawerNavigator<FormsDrawerNavigationParamList>();

export const FormsDrawer: FunctionComponent<
  PropsWithChildren<FormsDrawerProps>
> = (props) => {
  const theme = useTheme();
  const [roles, setRoles] = useState<UserRoleDto[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { data } = useUserState();

  useEffect(() => {
    if (data) {
      RoleService.userRoleGetRolesByUserId(data.id)
        .then(setRoles)
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <DrawerNavigator.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        sceneContainerStyle: {
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      initialRouteName="forms-list"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Screen
        name="submissions-list"
        options={{
          ...navigationDrawerStyle,
          drawerLabel: 'Submissions',
          drawerIcon: ({ color, size }) => (
            <Icon icon={TrayIcon} color={color} size={size} />
          ),
        }}
        component={() => (
          <LoadingRouteGuard
            loading={loading}
            guards={[InternalUserGuard(roles)]}
            component={<Submissions />}
            noAccessComponent={<AccessDenied />}
          />
        )}
      />

      <DrawerNavigator.Screen
        name="forms-list"
        options={{
          ...navigationDrawerStyle,
          drawerLabel: 'Forms',
          drawerIcon: ({ color, size }) => (
            <Icon icon={DocumentIcon} color={color} size={size} />
          ),
        }}
        component={() => (
          <LoadingRouteGuard
            loading={loading}
            guards={[InternalUserGuard(roles)]}
            component={<Forms />}
            noAccessComponent={<AccessDenied />}
          />
        )}
      />
      {/* These detail views have display none as we're not displaying them in the drawer*/}
      <DrawerNavigator.Screen
        name="submission-view"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={({ navigation, route }) => (
          <LoadingRouteGuard
            loading={loading}
            guards={[InternalUserGuard(roles)]}
            component={<ViewSubmission navigation={navigation} route={route} />}
            noAccessComponent={<AccessDenied />}
          />
        )}
      />
      <DrawerNavigator.Screen
        name="edit-form"
        options={{
          drawerItemStyle: { display: 'none' },
        }}
        component={({ navigation, route }) => (
          <LoadingRouteGuard
            loading={loading}
            guards={[InternalUserGuard(roles)]}
            component={<EditForm navigation={navigation} route={route} />}
            noAccessComponent={<AccessDenied />}
          />
        )}
      />
    </DrawerNavigator.Navigator>
  );
};

interface FormsDrawerProps {}

export type FormsDrawerNavigationParamList = {
  'submissions-list': undefined;
  'tables-list': undefined;
  'forms-list': undefined;
  'submission-view': {
    formId: string;
    submissionId: string;
  };
  'edit-form': {
    formId: string;
  };
};

export type FormsDrawerNavigationProp =
  DrawerNavigationProp<FormsDrawerNavigationParamList>;

function CustomDrawerContent(props: any) {
  return (
    <>
      <DrawerContentScrollView {...props}>
        <NewFormModal />
        <FormsImportStepper />
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </>
  );
}
