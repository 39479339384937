import React, { useEffect, useState } from 'react';
import { Modal } from 'assets/components/modal';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useUserState } from '../../store/user-store';
import RadioButton from 'assets/components/radio-button-group/RadioButton';
import { useAppStateStore } from '../../store/app-store';
import theme from '../../../../../packages/assets/theme/theme';

const useStyles = makeStyles((theme) => ({
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  dropdownElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const PharmacyStoreSelector = () => {
  const styles = useStyles();

  const { data } = useUserState();
  const { locationId, userLocations, getUserLocations } = useAppStateStore();

  const [showModal, setShowModal] = useState(false);
  const [stores, setStores] = useState<Array<{ id: string; value: string }>>(
    [],
  );

  const methods = useForm({
    defaultValues: {
      pharmacyStoreSelector: locationId,
    },
  });

  const handleDismissModal = () => {
    setShowModal(false);
  };

  const handleOnSave = () => {
    handleDismissModal();
  };

  const handleOnClick = () => {
    if (stores.length > 5) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (!data) return;

    (async () => {
      if (userLocations.length === 0) {
        await getUserLocations(data.id);
      }
    })();
  }, []);

  useEffect(() => {
    for (const location of userLocations) {
      setStores((current) => [
        ...current,
        {
          id: location.id,
          value: location.name,
        },
      ]);
    }
  }, [userLocations]);

  useEffect(() => {
    useAppStateStore.setState({
      locationId: methods.getValues().pharmacyStoreSelector,
    });
  }, [methods.getValues().pharmacyStoreSelector]);

  return (
    <>
      <Button
        hierarchy="pharmacy-primary"
        logger={{ id: 'dropdown-button-id' }}
        onPress={handleOnClick}
        style={{
          backgroundColor: 'transparent',
          borderRadius: theme.roundness,
        }}
      >
        <Form methods={methods}>
          <View style={styles.dropdownElement}>
            <DropdownSelect
              fieldName="pharmacyStoreSelector"
              options={stores.map((item) => ({
                label: item.value,
                value: item.id,
              }))}
              fontColor={theme.palette.gray[100]}
              disabled={stores.length > 5}
              isSearchable
              navBarDropdown
            />
          </View>
        </Form>
      </Button>
      <Modal
        size="sm"
        title="Find your store"
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          logger: { id: 'cancel-button-modal-store-selector' },
        }}
        okButtonProps={{
          onPress: handleOnSave,
          logger: { id: 'ok-button-modal-store-selector' },
        }}
      >
        {stores.map((item) => (
          <View style={styles.radioButtonContainer} key={item.id}>
            <RadioButton
              selected={
                methods.getValues().pharmacyStoreSelector === item.value
              }
              onValueChange={(value) => {
                methods.setValue('pharmacyStoreSelector', value);
              }}
              text={item.value}
              value={item.value}
            />
          </View>
        ))}
      </Modal>
    </>
  );
};
