import moment from 'moment';
import { Order } from './types';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export function compare(
  currentItem: any,
  nextItem: any,
  key: string,
  order: Order = Order.DESC,
  isDate = true,
) {
  const aCompared = isDate
    ? new Date(currentItem[key]).getTime()
    : currentItem[key];
  const bCompared = isDate ? new Date(nextItem[key]).getTime() : nextItem[key];
  const isDesc = order === Order.DESC;
  if (isDate) {
    const direction = isDesc ? bCompared - aCompared : aCompared - bCompared;
    return direction;
  } else {
    const direction = isDesc ? [-1, 1, 0] : [1, -1, 0];
    if (aCompared > bCompared) {
      return direction[0];
    } else if (aCompared < bCompared) {
      return direction[1];
    } else {
      return direction[2];
    }
  }
}

export function getFullName(user: any) {
  const { first_name, last_name, firstName, lastName } = user ?? {};
  if (first_name || last_name) {
    return first_name + ' ' + last_name;
  } else {
    return firstName + ' ' + lastName;
  }
}

// These rules apply strictly for past time
export const formatMessageDate = (
  inputDate: string,
  inputFormat?: string | undefined,
): string => {
  const dateDay = moment
    .utc(inputDate, inputFormat)
    .local()
    .format('MM/DD/YYYY');
  const dateDayFormatted = moment.utc(dateDay, 'MM/DD/YYYY').local();
  const localInputTime = moment.utc(inputDate, inputFormat).local();
  const localBaseTime = moment.utc().local();
  const diff = dateDayFormatted.diff(localBaseTime, 'days');
  const inputYear = localInputTime.year();
  const baseYear = localBaseTime.year();

  if (diff === 0) {
    // 3:50pm (if today)
    return localInputTime.format('h:mm a');
  } else if (diff > -8) {
    // 4d (if within the last week)
    return Math.abs(diff) + 'd';
  } else if (inputYear === baseYear) {
    // Dec 2, 10:06am (if this year, but not this week)
    return localInputTime.format('MMM D, h:mm a');
  } else {
    // 11/18/2021 10:43am (if not this year)
    return localInputTime.format('MM/DD/YYYY h:mm a');
  }
};

export const groupConversationsByPatient = (
  rawConversationsData: DirectMessagePatientDto[],
) => {
  const patientGroupings: Record<string, DirectMessagePatientDto> = {};
  const finalPatientsList: DirectMessagePatientDto[] = [];
  rawConversationsData.forEach((conversation) => {
    const existingConversation =
      patientGroupings[conversation.location_patient_id];

    if (existingConversation) {
      const existingDate = new Date(
        existingConversation.most_recent_qualifying_message_date,
      );
      const conversationDate = new Date(
        conversation.most_recent_qualifying_message_date,
      );

      if (
        (existingConversation.pharmacy_viewed_all_messages &&
          !conversation.pharmacy_viewed_all_messages) ||
        (existingConversation.pharmacy_viewed_all_messages ===
          conversation.pharmacy_viewed_all_messages &&
          existingDate < conversationDate)
      ) {
        existingConversation.most_recent_qualifying_message =
          conversation.most_recent_qualifying_message;
        existingConversation.most_recent_qualifying_message_date =
          conversation.most_recent_qualifying_message_date;
        existingConversation.pharmacy_viewed_all_messages =
          conversation.pharmacy_viewed_all_messages;
      }
    } else {
      patientGroupings[conversation.location_patient_id] = conversation;
    }
  });

  for (const [key, value] of Object.entries(patientGroupings)) {
    finalPatientsList.push(value);
  }

  return finalPatientsList;
};
